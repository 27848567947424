







































































































import Vue from 'vue';
import _ from 'lodash';
import notificationMixin from '../../mixin/notification';
import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import { SessionStatus } from "../../model/entity/tableSessionSetting";
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component('Settled', {
  components: {
    SHSettledCardSkeleton: () => import('../../components/components/SHSettledCardSkeleton.vue'),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [
    notificationMixin,
  ],
  data() {
    return {
      paymentsList: [],
      settledList: [],
      filteredSettledList: [],
      errors: {},
      sessionStatus: SessionStatus,
      searchWord: "",

      isLoading: false,
      statusDisplay: store.state.statusDisplay
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchSettleLists();
    store.dispatch('setPreviousPage', 'settled');
  },
  computed: {
    setting() {
      return store.state.shopSetting;
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    statusClass() {
      if (_.includes(this.statusDisplay, SessionStatus.STATUS_PAID)) {
        return 'Green';
      }

      return 'Red';
    },
    paidTab() {
      if (_.includes(this.statusDisplay, SessionStatus.STATUS_PAID)) {
        return true;
      }

      return false;
    }
  },
  methods: {
    fetchSettleLists() {
      this.isLoading = true;
      let message = '';
      api
        .printers_settled({ query: this.searchWord })
        .then(response => {
          if (response.data.status != "success") {
            message = response.data.message || translate('hall-0048', 'データの取得に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.paymentsList = response.data.data.paymentsList;
          this.settledList = response.data.data.settledList;
          this.filteredSettledList = _.filter(this.settledList, (item) => _.includes([this.sessionStatus.STATUS_RECEPTED, this.sessionStatus.STATUS_PAYING], item.status));
        })
        .catch((err) => {
            this.isLoading = false;
            message = err.response.data.message || translate('hall-0048', 'データの取得に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
        })
    },
    isSelect(status: number[]) {
      const check = _.some(status, statusElem => _.includes(this.statusDisplay, statusElem));
      if (check) {
        return 'select';
      }

      return '';
    },
    setStatus(status) {
      this.statusDisplay = status;
      store.dispatch("setStatusDisplay", status);
    },
    total(list) {
      let result = 0;

      _.map(list, function (item) {
          result = result + item.table_total_include;
      });

      const formatter = new Intl.NumberFormat('ja-JP');

      return "¥" + formatter.format(result);
    },
    displayTotal() {
      if (_.includes(this.statusDisplay, SessionStatus.STATUS_RECEPTED)) {
        let text = translate("hall-0175", "売上見込み額");
      return text + '：' + this.total(this.filteredSettledList)
      }

      let text = translate("hall-0176", "売上確定額");
      return text + '：' + this.total(this.paymentsList)

    },
    seatName(tableSession) {
      let name = '';
      const id = tableSession.seat_id;
      if (tableSession.is_quick) {
        return 'Quick Order';
      }

      _.forEach(store.state.tableGroups, function (tableGroup) {
        _.forEach(tableGroup.table_seats, function (table_seat) {
          if (table_seat.id == id) {
            name = table_seat.name;
            return;
          }
        });
      });

      return name;
    },
    linkTo(list) {
      let url = list.table_session.id;
      if (list.settle_no) {
        return url + "#no" + list.settle_no;
      }

      return url;
    },
    handleClickSearch: function (e) {
      if (e.keyCode !== 13) {
        return;
      }
      this.fetchSettleLists();
    },
    handleClickResetSearch: function () {
      this.searchWord = "";
      this.fetchSettleLists();
    },
  },
  filters: {
      priceFilter: function(price) {
          const formatter = new Intl.NumberFormat('ja-JP');

          return "¥" + formatter.format(price);
      },
      numberFormat(number) {
          if (typeof number !== "number") {
              return number;
          }
          return number.toLocaleString();
      },
      toStatusLabel(table): string {
          const status = table.status;
          switch(status) {
              case SessionStatus.STATUS_PAYING:
                  return 'paying';
                case SessionStatus.STATUS_RECEPTED:
                  return 'recepted';
          }

          return '';
      }
  },
});
